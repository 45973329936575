import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './form.css';
import './radio.css';

function ContactForm() {
    const form = useRef();
    const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);
    const [inputSets, setInputSets] = useState([]);
    const [isButtonVisible, setIsButtonVisible] = useState(true); // Add state for button visibility
    const [isModalOpen, setIsModalOpen] = useState(false);

    const sendEmail = async (e) => {
        e.preventDefault();

        try {
            await emailjs.sendForm('service_bduc7xy', 'template_e4nbinr', form.current, 'sOPww7yBfXp9ur3K_');
            console.log('Email sent successfully');
            form.current.reset(); // Reset the form
            setIsModalOpen(true); // Open the modal upon successful submission
        } catch (error) {
            console.error('Email sending failed:', error);
        }
    };

    const addInputSet = () => {
        setInputSets([...inputSets, inputSets.length]);
    };

    const removeInputSet = (indexToRemove) => {
        // Remove the specified input set from the array
        const updatedInputSets = inputSets.filter((_, index) => index !== indexToRemove);

        // If the length of updatedInputSets is 1, set it to an empty array
        setInputSets(inputSets.length === 1 ? [] : updatedInputSets);
    };

    return (
        <div className="form-container">
            <div className="form-content">
                <div className="topic-text">Let’s get you covered</div>
                <p color="black">
                    I’ll show you plans that fit your needs — and your budget.
                </p>
                <p>ከፍላጎትዎ ጋር የሚስማሙ ዕቅዶችን እና ባጀትዎን አሳይሻለሁ።</p>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="grid-container-element">
                        <div className="grid-child-element firstName" width="50">
                            <label className="label">First Name </label>
                            <div className="input-box" width="50">
                                <input type="text" required={true} name="firstName" placeholder="First Name " />
                            </div>
                        </div>
                        <div className="grid-child-element lastName">
                            <label className="label">Last Name </label>
                            <div className="input-box">
                                <input type="text" required={true} name="lastName" placeholder="Last Name " />
                            </div>
                        </div>
                        <div class="grid-child-element email">
                            <label for="email" class="label">
                                Email <span class="icon">&#x1F4E7;</span>
                            </label>
                            <div class="input-box">
                                <input type="email" required="true" name="email" placeholder="Email " />
                            </div>
                        </div>
                        <div class="grid-child-element phone">
                            <label for="phone" class="label">
                                Phone Number <span class="icon">&#x1F4DE;</span>
                            </label>
                            <div class="input-box">
                                <input type="tel" id="phone" required="true" name="phoneNumber" placeholder="123-456-7777" />
                            </div>
                        </div>
                        <div className="grid-child-element gender">
                            <label className='label' for="gender">Gender </label>
                            <div className="radio-wrapper" >
                                <div className="radio-container" >
                                    <input type="radio" name="gender" value="Male" id="male" />
                                    <label for="male">Male</label>
                                    <input type="radio" name="gender" value="Female" id="female" />
                                    <label for="female">FEMALE</label>
                                </div>
                            </div>
                        </div>
                        <div className="grid-child-element citizen" >
                            <label className='label' for="citizen">Citizen </label>
                            <div className="radio-wrapper"  >
                                <div className="radio-container" >
                                    <input type="radio" name="citizen" value="Yes" id="yes" />
                                    <label for="yes">YES</label>
                                    <input type="radio" name="citizen" value="No" id="no" />
                                    <label for="no">NO</label>
                                </div>
                            </div>
                        </div>
                        <div className="grid-child-element dateOfBirth">
                            <label className="label">Date of Birth </label>
                            <div className="input-box">
                                <input type="date" required={true} name="birthDate" placeholder="Date of Birth" />
                            </div>
                        </div>

                        <div className="grid-child-element income">
                            <label className="label">Annual Income </label>
                            <div className="input-box">
                                <span class="currency-code">$</span>
                                <input class="text-currency" type="number" name="annualIncome" />
                            </div>

                        </div>
                        <div className="grid-child-element address">
                            <label className='label'>Address </label>
                            <div className="input-box">
                                <input type="text" required={true} name="address" placeholder="Address" />
                            </div>
                        </div>
                        <div className="grid-child-element city">
                            <label className='label'>City </label>
                            <div className="input-box">
                                <input type="text" required={true} name="city" placeholder="City" />
                            </div>
                        </div>

                        <div className="grid-child-element state">
                            <label className='label'>State </label>
                            <div className="input-box">
                                <input type="text" required={true} name="state" placeholder="State" />
                            </div>
                        </div>
                        <div className="grid-child-element zip">
                            <label className='label'>Zip </label>
                            <div className="input-box">
                                <input type="number" required={true} name="zip" placeholder="Zip" />
                            </div>
                        </div>
                    </div>

                    {inputSets.map((setIndex) => (
                        <div key={setIndex}>
                            <label className="label"> </label>
                            <div className="grid-container-element">
                                <div className="grid-child-element additionalPersonFirstName" width="50">
                                    <label className="label">First Name </label>
                                    <div className="input-box" width="50">
                                        <input type="text" name={`additionalPersonFirstName${setIndex + 1}`} placeholder="First Name " />
                                    </div>
                                </div>
                                <div className="grid-child-element additionalPersonLastName">
                                    <label className="label">Last Name </label>
                                    <div className="input-box">
                                        <input type="text" name={`additionalPersonLastName${setIndex + 1}`} placeholder="Last Name " />
                                    </div>
                                </div>
                                <div className="grid-child-element additionalPersonDateOfBirth">
                                    <label className="label">Date of Birth </label>
                                    <div className="input-box">
                                        <input type="date" name={`additionalPersonDateOfBirth${setIndex + 1}`} placeholder="Date of Birth" />
                                    </div>
                                </div>

                                <div className="grid-child-element additionalPersonCitizen" >
                                    <label className='label'>Citizen </label>
                                    <div className="radio-wrapper"  >
                                        <div className="radio-container" >
                                            <input type="radio" name={`additionalPersonCitizen${setIndex + 1}`} value="Yes" id={`yes${setIndex + 1}`} />
                                            <label for={`yes${setIndex + 1}`} >YES</label>
                                            <input type="radio" name={`additionalPersonCitizen${setIndex + 1}`} value="No" id={`no${setIndex + 1}`} />
                                            <label for={`no${setIndex + 1}`} >NO</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-element additionalPersonGender" >
                                    <label className='label' for="additionalPersonGender">Gender </label>
                                    <div className="radio-wrapper"  >
                                        <div className="radio-container" >
                                            <input type="radio" name={`additionalPersonGender${setIndex + 1}`} value="Male" id={`male${setIndex + 1}`} />
                                            <label for={`male${setIndex + 1}`} >MALE</label>
                                            <input type="radio" name={`additionalPersonGender${setIndex + 1}`} value="Female" id={`female${setIndex + 1}`} />
                                            <label for={`female${setIndex + 1}`} >FEMALE</label>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="button">
                                <input
                                    type="button"
                                    value="- Remove"
                                    onClick={() => removeInputSet(setIndex)}
                                />
                            </div>

                        </div>
                    ))}

                    <div className="button-container">
                        {inputSets.length < 5 && (
                            <div className="button">
                                <input
                                    style={{ width: '300px' }}
                                    type="button"
                                    value='+ Additional Person'
                                    onClick={addInputSet}
                                />
                            </div>
                        )}
                        <div className="button">
                            <input style={{ width: '300px' }} // Set a fixed width for the button
                                type="submit" value="Get In Touch" />
                        </div>
                    </div>
                    {isModalOpen && (
                        <div id="openModal" className="modalDialog">
                            <div>
                                <a href="#close" title="Close" className="close" onClick={() => setIsModalOpen(false)}>
                                    X
                                </a>
                                <p>Your Request has been successfully submitted! I will get back to you soon!</p>
                                <div className="button">
                                    <input
                                        style={{ width: '100px' }}
                                        type="button"
                                        value='Close'
                                        onClick={() => setIsModalOpen(false)}
                                    />
                                </div>
                            </div>
                            <a href="#close" title="Close" className="close" onClick={() => setIsModalOpen(false)}>
                                x
                            </a>
                        </div>
                    )}
                </form>

            </div>
        </div>

    );
}

export default ContactForm;