import React, { useRef } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import { Carousel } from 'react-responsive-carousel';
import './App.css';
import './form.css';
import './nav.css'
import './header.css'
import './radio.css'
import './footer.css'
import emailjs from '@emailjs/browser';
import ContactForm from './ContactForm'; // Import the ContactForm component

const images = ['3health.jpg', 'health-insurance3.jpeg', 'health_insurance4.png']; // Replace with your image paths
const imageWidth = 1024; // Set the width of the images
const imageHeight = 400; // Set the height of the images

function App() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bduc7xy', 'template_e4nbinr', form.current, 'sOPww7yBfXp9ur3K_')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="App">
      <header className="header_container">
        <div className="left-item">
          <img  src='logo.png' width={100} height={100} />
        </div>
        <div className="about-label">
          <h1 >Obama Care / Medicare Advantage / Medicaid</h1>
          <p >phone: (703) 655-7386</p>
          <p >email: mulucarellc@gmail.com</p>

        </div>
        <div></div>
      </header>
      <hr style={{ margin: '0px 0', border: 'none', borderBottom: '3px solid #34495e' }} />

      <main className="mainContent">

        <div className="grid-about-container-element">
          <div style={{ float: 'right', marginLeft: '120px' }}>
            <img
              style={{ maxWidth: '100%', maxHeight: '300px', height: 'auto' , border: "3px solid #34495e", borderRadius: "10%"}}
              src="./mulu.jpg"
            />
          </div>
          <div className="about-info">
            <div className="button">
              <label className="name-text">Mulu Wassie</label>
            </div>
            <div className="button">
              <label className="about-label">Licensed insurance agent</label>
            </div>
            <div className="button">
              <label className="about-label">Leesburg, VA 20176 </label>
            </div>
          </div>
          <div>
            <div className="about-text"  >
              <p border= "3px solid #34495e" >
              We have been a licensed, independent agent specializing in insurance, particularly with ACA (Obamacare), since 2015. 
              Our expertise also extends to Medicare and Medicaid. </p>
              <p>-</p>
              <p>
              ከ2015 ጀምሮ በጤና ኢንሹራንስ መስክ፣በተለይ ከኤሲኤ (ኦባማኬር) ጋር እየሰራን ያለን ፈቃድ ያለን ገለልተኛ ወኪልነን።በሜዲኬር እና ሜዲኬይድም እገልግሎት እንሰጣለን።
              </p>
            </div>
          </div>
        </div>

        <div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  <Carousel showThumbs={false} autoPlay={true} interval={5000} infiniteLoop={true} width={imageWidth} height={imageHeight}>
    {images.map((image, index) => (
      <div key={index}>
        <img
          src={image}
          alt={`Image ${index + 1}`}
          width={imageWidth}
          height={imageHeight}
        />
      </div>
))}
  </Carousel>
</div>
        <ContactForm /> {/* Use the ContactForm component */}

        <div>

          <section className="footer">
    
            <p className="copyright">Mulu Care @ 2023</p>
          </section>
        </div>
      </main >
    </div >
  );
}

export default App;